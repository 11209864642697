import React from "react";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import styled from "styled-components";
import { H1 } from "app/components/Typography";

export const CenterWrapper = styled(Flex).attrs({
  justifyContent: "center",
})``;

export const Container = styled(Div).attrs({
  px: [3, 3, 4],
  maxWidth: "1600px",
  width: "100%",
})``;

export const Title = styled(H1)`
  line-height: 110%;
  margin-right: 32px;
  margin-bottom: 16px;
  padding-bottom: 0;
`;

interface Props {
  children: React.ReactNode;
}

export function BodyContainer({ children }: Props) {
  return (
    <CenterWrapper>
      <Container>{children}</Container>
    </CenterWrapper>
  );
}
